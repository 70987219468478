import React from "react"
import ImgOne from "../../icon/1.png"
import ImgTwo from "../../icon/2.png"
import ImgThree from "../../icon/3.png"
import ImgNone from "../../icon/4.png"
import ImgX from "../../icon/5.png"

const Medal = ({ one = false, two = false, three = false }) => {
  if (three)
    return <img className="min-h-full max-h-full" src={ImgOne} alt="medal" />
  else if (two)
    return <img className="min-h-full max-h-full" src={ImgTwo} alt="medal" />
  else if (one)
    return <img className="min-h-full max-h-full" src={ImgThree} alt="medal" />
  else
    return <img className="brightness-75 min-h-full max-h-full" src={ImgNone} alt="medal" />
}


export default React.memo(Medal);
