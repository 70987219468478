import React from "react"
import ImgOne from "../../icon/1.png"
import ImgTwo from "../../icon/2.png"
import ImgThree from "../../icon/3.png"
const Card = ({ title, content, medal }) => {
  return (
    <div className="w-full sm:w-4/12 xl:w-4/12">
      <div className="relative flex flex-col min-w-0 break-words rounded mb-6 xl:mb-0 shadow-lg">
        <div className="flex flex-wrap p-4 bg-gray-100">
          <div className="relative w-full max-w-full flex-grow flex-1 text-left">
            <div className="text-zinc-500 uppercase font-bold text-base">
              {title}      </div>
            <span className="font-semibold text-xl text-zinc-700">
              {Math.floor(content * 100)}%
            </span>
          </div>
          <div className="relative w-auto pl-4 flex-initial">
            {medal === 1 ? <img className="p-2 text-center inline-flex items-center justify-center w-14 h-14 shadow-lg rounded-full bg-main_light" src={ImgOne} alt="icon" /> : ""}
            {medal === 2 ? <img className="p-2 text-center inline-flex items-center justify-center w-14 h-14 shadow-lg rounded-full bg-main_light" src={ImgTwo} alt="icon" /> : ""}
            {medal === 3 ? <img className="p-2 text-center inline-flex items-center justify-center w-14 h-14 shadow-lg rounded-full bg-main_light" src={ImgThree} alt="icon" /> : ""}
          </div>
        </div>
      </div>
    </div>
  )
}


export default React.memo(Card);
