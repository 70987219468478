import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FirebaseRepository from '../service/firebase_repository';
import { firebaseApp } from '../service/firebase_config';
import FirebaseRealtime from '../service/firebase_realtime';
import { firebaseAppSub } from '../service/firebase_config_sub';

const firebaseRepository = new FirebaseRepository(firebaseApp);
const firebaseRealtime = new FirebaseRealtime(firebaseApp);

const initialState = {
  loading: true,
  signData: '',
  signState: false,
  load: false,
  totalData: 0,
  weekData: 0,
  userCnt: 0,
  week: 0,
  startWeek: 0,
  plan: false,
};

/* DB */
export const updateUser = createAsyncThunk(
  'firebase/user',
  async ({ user, week, data }) => {
    console.log(user, week, data)
    await firebaseRepository.updateUser(user, week, data)
  }
)

export const readData = createAsyncThunk(
  'firebase/setting/week',
  async ({ user }) => {
    console.log('readStartWeek')
    const response = await firebaseRepository.readStore(user)
    return response
  }
)

export const readVipData = createAsyncThunk(
  'firebase/membership/read',
  async ({ email }) => {
    console.log('membershipVipRead')
    const response = await firebaseRepository.readVipStore(email)
    console.log("response", response)
    return response
  }
)


export const updatePlan = createAsyncThunk(
  'firebase/plan/update',
  async ({ plan }) => {
    console.log('updatePlan', plan)
    await firebaseRepository.updatePlan(plan)
  }
)
export const readPlan = createAsyncThunk(
  'firebase/plan/read',
  async () => {
    console.log('readPlan')
    const response = await firebaseRepository.readPlan()
    console.log("response", response)
    return response
  }
)


/* RTDB */
export const setLog = createAsyncThunk(
  'firebase/rtdb/setLogs',
  async ({ key, email, name, level, msg }) => {
    console.log('setLog', email, name, level, msg)
    await firebaseRealtime.setLog(key, email, name, level, msg)
  }
)

export const removeLog = createAsyncThunk(
  'firebase/rtdb/setLogs',
  async ({ key }) => {
    console.log('removeLog', key)
    await firebaseRealtime.removeLog(key)
  }
)

export const firebaseRTK = createSlice({
  name: 'firebase',
  initialState,
  reducers: {
    setLogData: (state) => {
      console.log('setLog')
      setLog('setLog')
    },
    removeLogData: (state) => {
      console.log('removeLogData')
      removeLog('removeLogData')
    },
  },
  extraReducers: {
    [updatePlan.fulfilled.type]: (state) => {
      console.log('updatePlan', state)
    },
    [readPlan.pending.type]: (state) => {
      state.loading = true;
    },
    [readPlan.fulfilled.type]: (state, action) => {
      console.log('readPlan', action, action.payload)
      state.plan = action.payload
      state.loading = false;
      console.log("readPlan", action.payload)
    },
    [updateUser.fulfilled.type]: (state) => {
      console.log('updateUser', state)
    },
    [readData.pending.type]: (state) => {
      state.loading = true;
    },
    [readData.fulfilled.type]: (state, action) => {
      console.log('readData', action, action.payload)
      state.startWeek = action.payload.startWeek
      state.weekData = action.payload.weekData
      state.totalData = action.payload.totalData
      state.userCnt = action.payload.userCnt
      console.log(state.startWeek, state.weekData)
      state.loading = false;
    },
    [readVipData.pending.type]: (state) => {
      // state.loading = true;
    },
    [readVipData.fulfilled.type]: (state, action) => {
      console.log('readVipData', action, action.payload)
      if (!action.payload) {
        state.member = {
          "achievement": {
          },
          "inactiveAt": null,
          "generation": 0,
          "lastAt": null,
          "level": 1,
          "email": "",
          "createAt": "",
          "name": "",
          "state": "엑티브"
        }
      } else {
        state.member = action.payload
      }
      console.log('state', state.member)
      // state.loading = false;
    },
  }
})

export const { setLogData } = firebaseRTK.actions
