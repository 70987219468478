import React from "react"

const CircleCheckBox = ({ onClick, checked, disable }) => {
  const checkCircle = <svg xmlns="http://www.w3.org/2000/svg" className="h-full p-2 text-main_dark" viewBox="0 0 20 20" fill="currentColor">
    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
  </svg>
  const unCheckCircle = <svg xmlns="http://www.w3.org/2000/svg" className="h-full p-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
  </svg>
  return (
    <div onClick={onClick} className="cursor-pointer">
      {
        !disable ?
          checked ? checkCircle : unCheckCircle
          :
          <></>
      }
    </div>
  )
}

export default React.memo(CircleCheckBox);
