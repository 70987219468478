import React, { useEffect, useState } from "react"
import Card from "../Atoms/Card"
import CircleCheckBox from "../Atoms/CircleCheckBox"
import Medal from "../Atoms/Medal"
import { useDispatch, useSelector } from "react-redux";
import { updateUser, readData, setLog, readVipData, removeLog } from "../../modules/firebase";
import { searchParam } from "../../util/util"
import { useLocation } from "react-router-dom"
import moment from "moment"
import LogBoard from "../Atoms/LogBoard";
import { StartWeekDate } from "../../config/config";

const UserBoard = () => {
  const dispatch = useDispatch()
  const fbState = useSelector((state) => state.firebase);
  const [checkItems, setCheckItems] = useState(Array(7).fill(0))
  const [total, setTotal] = useState(0)
  const [email, setEmail] = useState('')
  const [name, setName] = useState()
  const { search } = useLocation();
  const week2 = moment(new Date()).format("YYYY") + moment(new Date()).isoWeeks()

  const currentTime = new Date(); // 현재 시간 가져오기
  const fourHoursAgo = new Date(currentTime.getTime() - 4 * 60 * 60 * 1000); // 4시간 전의 시간 계산 (밀리초 단위)
  const today = fourHoursAgo.getDate()
  const week = moment(fourHoursAgo).format('YYYY') + String(moment(fourHoursAgo).isoWeek()).padStart(2, "0");

  // const today = new Date().getDate()
  const [logKeys, setLogKeys] = useState(Array(7).fill(false))

  const msg = [
    "스트렝스로 훈련으로 강해지셨습니다!",
    "스트렝스로 훈련으로 강해지셨습니다!",
    "스트렝스로 훈련으로 강해지셨습니다!",
    "무브먼트 연습으로 더 자유로워지셨습니다!",
    "무브먼트 연습으로 더 자유로워지셨습니다!",
    "피로저항으로 지구력이 상승되었습니다.",
    "P.I.A 훈련으로 가동범위가 향상되었습니다.",
  ]

  const handlerChecked = (id) => {
    var items = [...checkItems]
    var tempLogKeys = [...logKeys]
    var total = 0
    var strength = 0
    var movement = 0
    var update = true
    var logUpdat = false
    var key = window.btoa(Date.now() + email)

    if (items[id] === 0) {
      items[id] = today
      logUpdat = true
      tempLogKeys[id] = key
      setLogKeys(tempLogKeys)
    } else if (items[id] === today) {
      items[id] = 0
    } else {
      update = false
    }

    for (var i = 0; i < 3; i++) {
      if (items[i] === today)
        strength++
      console.log('strength', strength, items[i] === today)
    }

    for (var i = 3; i < 5; i++) {
      if (items[i] === today)
        movement++
    }

    if (strength <= 1 && movement <= 1 && update) {
      total = handlerCheckedSum(items)

      setCheckItems(items)
      setTotal(total)
      dispatch(updateUser({
        user: email,
        week: week,
        data: { challenge: items, total: total }
      }))

      if (logUpdat) {
        console.log('set', logKeys[id], key)
        dispatch(setLog({ key: key, email: email, name: name, level: fbState.member.level, msg: msg[id] }))
      } else {
        console.log('remove', logKeys[id], key)
        dispatch(removeLog({ key: logKeys[id] }))
      }
    }
    console.log(
      strength,
      movement,
      'season',
      email,
      week,
      { challenge: items, total: total })
  }
  const handlerCheckedSum = (items = []) => {
    return items.filter(e => e >= 1).length
  }

  useEffect(() => {
    const paramEmail = searchParam(search, 'email')
    const paramName = searchParam(search, 'name')
    setEmail(paramEmail)
    setName(paramName)
    if (paramEmail !== undefined && paramEmail !== null && paramEmail !== '') {
      dispatch(readData({ user: paramEmail }))
      dispatch(readVipData({ email: paramEmail }))
    }
  }, []);

  const MedalList = () => {
    var medal = []
    for (var i = StartWeekDate; i < StartWeekDate + 8; i++) {
      try {
        const total = fbState.weekData[i].total
        medal.push(<Medal one={total >= 1} two={total >= 5} three={total >= 7} />)
      }
      catch (e) {
        medal.push(<Medal />)
      }
    }
    return medal
  }

  useEffect(() => {
    console.log('fbState.weekData', fbState)
    try {
      if (fbState.weekData !== undefined && fbState.weekData !== null) {
        console.log('weekData', [week], fbState.weekData[week].challenge)
        setCheckItems(fbState.weekData[week].challenge)
        setTotal(fbState.weekData[week].total)
      }
    } catch (e) { }
  }, [fbState.weekData]);


  useEffect(() => {
    console.log('startWeek', fbState, week)
  }, [fbState.startWeek]);

  const totalCalculate = (field) => {
    let value = 0
    try {
      value = fbState.loading ? 0 :
        (fbState.totalData[week][field] / fbState.userCnt) >= 1 ? 1
          : (fbState.totalData[week][field] / fbState.userCnt)
    } catch (error) {
      value = 0
    }

    return value;
  }

  return (
    <>
      <div className="max-w-screen-lg md:max-w-screen-md mx-auto ">
        {week >= fbState.startWeek ? <LogBoard /> : <></>}
        {/* {today}/{week}/{week2} */}
        {/* TOTAL */}
        <h1 className="text-xl font-semibold text-center text-gray-500 hover:text-gray-900">이번주 챌린저 달성률</h1>
        <div className="mx-auto w-full" onClick={() => { }}>
          <div className="flex flex-wrap">
            <Card
              title='1회 이상 달성자'
              content={totalCalculate("one")}
              medal={1}
            />
            <Card
              title='70% 이상 달성'
              content={totalCalculate("seventy")}
              medal={2}
            />
            <Card
              title='100% 달성자'
              content={totalCalculate("hundred")}
              medal={3}
            />
          </div>
        </div>

        {/* 체크박스 */}
        <h1 className="text-xl font-semibold text-center text-gray-500 hover:text-gray-900">회원님의 달성률</h1>
        <div className="uppercase grid gap-4 grid-cols-1 w-full font-bold md:text-lg sm:text-base text-xs">
          <div className="rounded-lg p-2 sm:h-24 h-20 shadow-lg bg-gray-100 grid grid-cols-4 justify-items-center">
            <div className="flex justify-center items-center w-full text-center border-r-4">TOTAL</div>
            <Medal one={total >= 1} />
            <Medal two={total >= 5} />
            <Medal three={total >= 7} />
          </div>

          <div className="rounded-lg p-2 sm:h-24 h-20 shadow-lg bg-gray-100 grid grid-cols-4 justify-items-center">
            <div className="flex justify-center items-center w-full text-center border-r-4">MAX STRENGTH</div>
            <CircleCheckBox onClick={() => handlerChecked(0)} checked={checkItems[0] >= 1} />
            <CircleCheckBox onClick={() => handlerChecked(1)} checked={checkItems[1] >= 1} />
            <CircleCheckBox onClick={() => handlerChecked(2)} checked={checkItems[2] >= 1} />
          </div>
          <div className="rounded-lg p-2 sm:h-24 h-20 shadow-lg bg-gray-100 grid grid-cols-4 justify-items-center">
            <div className="flex justify-center items-center w-full text-center border-r-4">MOVEMENT PRACTICE</div>
            <CircleCheckBox onClick={() => handlerChecked(3)} checked={checkItems[3] >= 1} />
            <CircleCheckBox onClick={() => handlerChecked(4)} checked={checkItems[4] >= 1} />
            <CircleCheckBox disable />
          </div>

          <div className="rounded-lg p-2 sm:h-24 h-20 shadow-lg bg-gray-100 grid grid-cols-4 justify-items-center">
            <div className="flex justify-center items-center w-full text-center border-r-4">FATIGUE RESISTANCE</div>
            <CircleCheckBox onClick={() => handlerChecked(5)} checked={checkItems[5] >= 1} />
            <CircleCheckBox disable />
            <CircleCheckBox disable />        </div>

          <div className="rounded-lg p-2 sm:h-24 h-20 shadow-lg bg-gray-100 grid grid-cols-4 justify-items-center">
            <div className="flex justify-center items-center w-full text-center border-r-4">P.I.A STRETCHING</div>
            <CircleCheckBox onClick={() => handlerChecked(6)} checked={checkItems[6] >= 1} />
            <CircleCheckBox disable />
            <CircleCheckBox disable />
          </div>
        </div>
        {/* 종합 */}
        <h1 className="text-xl font-semibold text-center text-gray-500 hover:text-gray-900 mt-10">8주 종합결과</h1>
        <div className="uppercase grid gap-4 grid-cols-1 w-full font-bold md:text-lg sm:text-base text-xs mb-8">
          <div className="rounded-lg p-2 shadow-lg bg-gray-100 grid grid-cols-4 justify-items-center">
            <div className="flex justify-center items-center w-full text-center border-r-4">8주 종합</div>
            <div className="grid grid-cols-4 sm:h-40 h-28 gap-4 col-span-3">
              {MedalList()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(UserBoard);
