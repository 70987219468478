import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { readPlan, updatePlan } from "../modules/firebase";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PlanPage = () => {
  const dispat = useDispatch()
  const fbState = useSelector((state) => state.firebase);
  const [plan, setPlan] = useState(Array(100).fill(''))
  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  })
  const slickRef = useRef(null);

  useEffect(() => {
    if (fbState.plan !== false) {
      // console.log('usePlan', fbState.plan)
      setPlan(fbState.plan)

      for (var i = 1; i <= 16; i++) {
        try {
          window.document.getElementById(i).value = fbState.plan[i]
          console.log('document', window.document.getElementById(i).value)
        } catch { }
      }
      for (var i = 20 + 1; i <= 20 + 16; i++) {
        try {
          window.document.getElementById(i).value = fbState.plan[i]
          console.log('document', window.document.getElementById(i).value)
        } catch { }
      }
      for (var i = 40 + 1; i <= 40 + 16; i++) {
        try {
        } catch { }
      }
    }
  }, [fbState]);

  useEffect(() => {
    dispat(readPlan())
    setTimeout(function () { postMsg() },
      100)
    setTimeout(function () { postMsg() },
      500)
    setTimeout(function () { postMsg() },
      1000)
  }, [])

  useEffect(() => {
    for (var i = 40 + 1; i <= 40 + 16; i++) {
      console.log(i, plan[i] !== "" && !(new Date(plan[i] + " 00:00:00") >= new Date()) && !(new Date(plan[i + 1] + " 00:00:00") <= new Date()))

      if (plan[i] !== "" && !(new Date(plan[i] + " 00:00:00") >= new Date()) && !(new Date(plan[i + 1] + " 00:00:00") <= new Date())) {
        slickRef.current.slickGoTo(i - 41)
      }
    }
  }, [plan])

  const postMsg = () => {
    let message = { height: document.body.scrollHeight, width: document.body.scrollWidth };
    window.top.postMessage(message, "*");
    window.parent.postMessage(message, "*");
    console.log(message)
  }

  const previous = () => { slickRef.current.slickPrev() }
  const next = () => { slickRef.current.slickNext() }

  window.onresize = function (event) {
    postMsg()
  }
  // window.addEventListener("mousemove", function () {
  // })
  return (
    <>
      <div className="grid grid-cols-10">
        <div className="hidden sm:block">
          <div className="flex flex-col self-center items-end justify-center cursor-pointer h-full" onClick={previous}>
            <div className="border-2 rounded-full border-gray-500 p-1 w-fit">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-600">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </div>
          </div>
        </div>
        <div className="sm:col-span-8 col-span-10">
          <Slider className="h-full" {...settings} ref={slickRef}>
            {
              plan.map((e, idx) => {
                if (idx >= 21 && idx <= 36) {
                  return plan[idx] === '' ? null :
                    <div className="p-2 h-full">
                      <div className={!(new Date(plan[idx + 20] + " 00:00:00") >= new Date()) && !(new Date(plan[idx + 21] + " 00:00:00") <= new Date()) ?
                        "text-gray-900 font-bold text-center flex-none" :
                        "text-gray-500 font-bold text-center flex-none"}>
                        {plan[idx]}
                      </div>
                      <div className="h-full pb-4">
                        <h3 className={!(new Date(plan[idx + 20] + " 00:00:00") >= new Date()) && !(new Date(plan[idx + 21] + " 00:00:00") <= new Date()) ?
                          "whitespace-pre-wrap text-left text-gray-800 break-words bg-amber-600/10 rounded-lg p-4 h-full" :
                          "whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full"}>
                          {plan[idx - 20]}
                        </h3>
                      </div>
                    </div>
                }
              })
            }
          </Slider>
        </div>
        <div className="hidden sm:block">
          <div className="flex flex-col self-center justify-center cursor-pointer h-full" onClick={next}>
            <div className="border-2 rounded-full border-gray-500 p-1 w-fit">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-600">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {postMsg()}
    </>
  );
}


export default React.memo(PlanPage);