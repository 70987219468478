import style from "./App.module.css"
import React, { useEffect, useState } from 'react';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import BoardPage from './pages/BoardPage';
import EndPage from './pages/EndPage';
import PlanPage from "./pages/PlanPage";

const App = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const checkMidnight = () => {
      const now = new Date();
      if (now.getHours() === 0 && now.getMinutes() === 0 && now.getSeconds() === 0) {
        console.log("reload()")
        window.location.reload();
      }
    };

    const timerId = setInterval(() => {
      checkMidnight();
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const searchParam = (key) => {
    return new URLSearchParams(document.location.search).get(key);
  };

  return (
    <div className={style.app} >
      <HashRouter >
        <Routes>
          <Route path="/" element={<BoardPage />} />
          <Route path="/plan" element={<PlanPage />} />
        </Routes>
      </HashRouter >
    </div >
  );
}



export default React.memo(App);
