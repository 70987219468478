import { getDatabase, limitToFirst, limitToLast, onValue, orderByKey, orderByValue, orderByChild, query, ref } from "firebase/database"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { firebaseApp } from "../../service/firebase_config"
import { firebaseAppSub } from "../../service/firebase_config_sub"
const LogBoard = (log) => {
  const [logList, setLogList] = useState()

  const handlerLog = (log) => {
    var logList = []
    var count = 0
    console.log("log", log)
    Object.keys(log).map((e) => {
      const today = new Date()
      const date = new Date(log[e].date)
      const diffMin = (today.getTime() - date.getTime()) / (60 * 1000)

      count++;

      console.log("log->e", e, date)
      if (count > 5) {
        logList.push(
          <div className="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center pl-0  pr-0  px-3 py-2 text-white rounded mb-5 flex-row space-y-0">
            <div className="w-8 h-8 bg-blue-200  absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-0">
              {diffMin >= 1 ?
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-auto mt-1.5 text-main_dark"
                  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-auto mt-1.5 text-main_dark"
                  fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                </svg>
              }
            </div>
            <div className="flex-auto w-full text-left -ml-0">
              <h3 className="text-base sm:text-lg font-bold -mt-0 text-gray-700 ">
                [{log[e].username}]님 {' '}
                <span className="text-main_dark   font-bold">
                  {log[e].log}
                </span>
              </h3>
              <small className="text-gray-500 ">
                {date.getFullYear().toString().slice(-2)}-{("0" + (date.getMonth() + 1)).slice(-2)}-{("0" + date.getDate()).slice(-2)} / {("0" + date.getHours()).slice(-2)}:{("0" + date.getMinutes()).slice(-2)}
              </small>
            </div>
          </div>
        )
      }
    })
    // logList.pop()
    console.log(logList)
    setLogList(logList)
  }

  const readLogs = () => {
    const store = getDatabase(firebaseApp);

    const logsRef = query(ref(store, "log"), orderByChild("date"), limitToLast(10));
    onValue(logsRef, (snapshot) => {
      var logs = []
      const data = snapshot.forEach((e) => {
        const data = e.val()
        logs.push(data)
      });

      handlerLog(logs)
    });
  }

  useEffect(() => {
    console.log("update")
  })


  useEffect(() => {
    readLogs()
  }, [])
  return (
    <>
      <div className="mx-auto relative shadow-xl pl-8 pr-4 sm:pl-8 lg:pr-8 py-4 my-8 rounded-lg bg-white ">
        <div className="flex flex-wrap items-center justify-between border-b-4">
          <h1 className="text-xl sm:text-3xl font-bold text-gray-800 ">로그</h1>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 sm:h-6 sm:w-6 sm:-ml-8 text-gray-700  cursor-pointer hover:text-gray-800  "
              viewBox="0 0 20 20" fill="currentColor">
              <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
            </svg>
          </span>
        </div>
        <div className="border-l-2 mt-10">
          {logList}
        </div>
      </div>
    </>
  );
}

export default React.memo(LogBoard);
