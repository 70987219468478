import React, { useEffect } from "react"
import style from "./BoardPage.module.css"
import TotalBoard from "../components/TotalBoard/TotalBoard";
import UserBoard from "../components/UserBoard/UserBoard";

import Dday from "../components/Atoms/Dday";
import { useSelector } from "react-redux";
import Spinner from "../components/Atoms/Spinner";
import moment from "moment"
import EndPage from "./EndPage";
import { StartWeekDate } from "../config/config";

const BoardPage = () => {
  // const week = moment(new Date()).format("YYYY") + moment(new Date()).isoWeeks()
  const currentTime = new Date(); // 현재 시간 가져오기
  const fourHoursAgo = new Date(currentTime.getTime() - 4 * 60 * 60 * 1000); // 4시간 전의 시간 계산 (밀리초 단위)
  const today = fourHoursAgo.getDate()
  const week = moment(fourHoursAgo).format('YYYY') + String(moment(fourHoursAgo).isoWeek()).padStart(2, "0");

  const fbState = useSelector((state) => state.firebase);
  console.log("thisnow",week)
  return (
    <>
      {!(week >= StartWeekDate && week <= StartWeekDate + 7) ?
        <><EndPage /></> :
        <>
          {/* <TotalBoard /> */}
          {console.log(week, "week")}
          <UserBoard />
          {/* <div className="text-white font-bold bg-black/80 absolute min-h-full min-w-full top-0" hidden={!fbState.loading}>
            <Spinner />
          </div> */}

          <div className="text-black font-bold bg-white absolute min-h-full min-w-full top-0" hidden={week >= fbState.startWeek || fbState.loading}>
            <div class="flex justify-center h-screen text-3xl">
              <div className="py-4">
                <div className="relative flex flex-col min-w-0 break-words rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="p-4 bg-gray-100 text-center">
                    <div className="w-full">
                      챌린지 점검중입니다.
                    </div>
                    <svg viewBox="0 0 100 50" className="-top-4">
                      {/* <rect width="100" height="100" rx="2" fill="#242539" /> */}
                      <g fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <g transform="translate(20 15)">
                          <path d="M 0 0 v 25 h 15 l 10 -15 h 10 v 20 h 25" stroke-dasharray="3 6" stroke-dashoffset="2" />
                          {/* <path class="in-between" stroke="#242539" stroke-width="2.5" d="M 60 30 h -25 v -20 h -10 l -10 15 h -15 v -25" /> */}
                        </g>

                        <g transform="translate(20 15)">
                          <path class="start" d="M -3 -3 l 6 6" />
                          <path class="start" d="M 3 -3 l -6 6" />
                        </g>

                        <g transform="translate(80 45)">
                          <g transform="translate(40 20)">
                            <g class="end">
                              <g transform="translate(-40 -20)">
                                <path d="M 0 0 q 6 -6 6 -10 a 6 6 0 0 0 -12 0 q 0 4 6 10" />
                                <circle cy="-10" r="2" stroke="none" fill="#000" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className={style.popup} hidden={week < fbState.startWeek + 8 || fbState.loading}>
            <h1>챌린지가 종료되었습니다.</h1>
          </div> */}
        </>
      }
    </>
  );
}


export default React.memo(BoardPage);