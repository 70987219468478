import { getDatabase, set, ref, onValue, remove } from "firebase/database";
import { Timestamp } from "firebase/firestore";

import { useDispatch, useSelector } from "react-redux";
import { onValueDB } from "../modules/firebase";
class FirebaseRealtime {
  constructor(app) {
    this.store = getDatabase(app);
  }

  async setLog(key, email, name, level, msg) {
    var title
    switch (level) {
      case 1:
        title = '초급자'
        break;
      case 2:
        title = '중급자'
        break;
      case 3:
        title = '상급자'
        break;
      default:
        title = '초급자'
        break;
    }
    console.log(key)

    set(ref(this.store, 'log/' + key), {
      username: name,
      title: title,
      email: email,
      log: msg,
      date: Date.now()
    });
    console.log('doneSet')
  }

  async removeLog(key) {
    console.log(key)
    if (key !== false) {
      remove(ref(this.store, 'log/' + key));
    }
  }
}

export default FirebaseRealtime;
