import { useLocation } from "react-router-dom";

export const groupBy = function (data, key) {
  return data.reduce(function (carry, el) {
    var group = el[key];

    if (carry[group] === undefined) {
      carry[group] = []
    }

    carry[group].push({ 'tag': el.tag, 'week': el.week })
    return carry
  }, {})
}

export const searchParam = (query, key) => {
  const res = new URLSearchParams(query).get(key);
  console.log(key, res)
  return res
};