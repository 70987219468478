import { getFirestore, getDocs, collection, getDoc, setDoc, doc, arrayUnion, arrayRemove, updateDoc } from "firebase/firestore";

class FirebaseRepository {
  constructor(app) {
    this.store = getFirestore(app);
  }

  async updateUser(challenger, week, data) {
    try {
      await setDoc(doc(this.store, "challenger", challenger, "challenge", `${week}`), data)
      console.log("Document written", data);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    const updateFields = {
      7: {
        one: arrayUnion(challenger),
        two: arrayUnion(challenger),
        three: arrayUnion(challenger),
        four: arrayUnion(challenger),
        five: arrayUnion(challenger),
        six: arrayUnion(challenger),
        seven: arrayUnion(challenger),
        two: arrayUnion(challenger),
        seventy: arrayUnion(challenger),
        hundred: arrayUnion(challenger),
      },
      6: {
        one: arrayUnion(challenger),
        two: arrayUnion(challenger),
        three: arrayUnion(challenger),
        four: arrayUnion(challenger),
        five: arrayUnion(challenger),
        six: arrayUnion(challenger),
        seven: arrayRemove(challenger),
        seventy: arrayUnion(challenger),
        hundred: arrayRemove(challenger),
      },
      5: {
        one: arrayUnion(challenger),
        two: arrayUnion(challenger),
        three: arrayUnion(challenger),
        four: arrayUnion(challenger),
        five: arrayUnion(challenger),
        six: arrayRemove(challenger),
        seven: arrayRemove(challenger),
        seventy: arrayUnion(challenger),
        hundred: arrayRemove(challenger),
      },
      4: {
        one: arrayUnion(challenger),
        two: arrayUnion(challenger),
        three: arrayUnion(challenger),
        four: arrayUnion(challenger),
        five: arrayRemove(challenger),
        six: arrayRemove(challenger),
        seven: arrayRemove(challenger),
        seventy: arrayRemove(challenger),
        hundred: arrayRemove(challenger),
      },
      3: {
        one: arrayUnion(challenger),
        two: arrayUnion(challenger),
        three: arrayUnion(challenger),
        four: arrayRemove(challenger),
        five: arrayRemove(challenger),
        six: arrayRemove(challenger),
        seven: arrayRemove(challenger),
        seventy: arrayRemove(challenger),
        hundred: arrayRemove(challenger),
      },
      2: {
        one: arrayUnion(challenger),
        two: arrayUnion(challenger),
        three: arrayRemove(challenger),
        four: arrayRemove(challenger),
        five: arrayRemove(challenger),
        six: arrayRemove(challenger),
        seven: arrayRemove(challenger),
        seventy: arrayRemove(challenger),
        hundred: arrayRemove(challenger),
      },
      1: {
        one: arrayUnion(challenger),
        two: arrayRemove(challenger),
        three: arrayRemove(challenger),
        four: arrayRemove(challenger),
        five: arrayRemove(challenger),
        six: arrayRemove(challenger),
        seven: arrayRemove(challenger),
        seventy: arrayRemove(challenger),
        hundred: arrayRemove(challenger),
      },
      0: {
        one: arrayRemove(challenger),
        two: arrayRemove(challenger),
        three: arrayRemove(challenger),
        four: arrayRemove(challenger),
        five: arrayRemove(challenger),
        six: arrayRemove(challenger),
        seven: arrayRemove(challenger),
        seventy: arrayRemove(challenger),
        hundred: arrayRemove(challenger),
      },
    };

    const updateField = updateFields[data.total];

    try {
      await updateDoc(doc(this.store, "total", `${week}`), updateField);
    } catch (e) {
      await setDoc(doc(this.store, "total", `${week}`), updateField);
    }
  }

  async readStore(challenger) {
    const settingRef = doc(this.store, "setting", "challenge");
    const settingSnapshot = await getDoc(settingRef);
    console.log('settingSnapshot.data()', settingSnapshot.data())

    const challengerRef = collection(doc(this.store, "challenger", challenger), "challenge");
    const challengerSnapshot = await getDocs(challengerRef);
    console.log('challengerSnapshot.data()', challengerSnapshot)

    const totalRef = collection(this.store, "total");
    const totalSnapshot = await getDocs(totalRef);
    console.log('totalSnapshot.data()', totalSnapshot)

    var totalData = new Object();
    totalSnapshot.forEach((doc) => {
      totalData[doc.id] = {
        one: doc.data().one.length,
        seventy: doc.data().seventy.length,
        hundred: doc.data().hundred.length,
      }
    });

    var weekData = new Object();
    challengerSnapshot.forEach((doc) => {
      weekData[doc.id] = doc.data()
    });

    console.log('totalData', totalData)
    console.log('weekData', weekData)
    return { "startWeek": settingSnapshot.data().startWeek, "userCnt": settingSnapshot.data().challengerCnt, "weekData": weekData, "totalData": totalData }
  }

  async readVipStore(email) {
    console.log(email)
    const membershipRef = doc(this.store, "membership", email);
    console.log(membershipRef)
    const membershipSnapshot = await getDoc(membershipRef);

    if (membershipSnapshot.exists()) {
      return membershipSnapshot.data()
    } else {
      return false
    }
  }



  async updatePlan(plan) {
    try {
      await setDoc(doc(this.store, "plan", "newPlan"), { 'plan': plan })
      console.log("Document written", plan);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async readPlan() {
    const planRef = doc(this.store, "plan", "newPlan");
    const planSnapshot = await getDoc(planRef);
    console.log('planSnapshot.data()', planSnapshot.data())

    return planSnapshot.data().plan
  }
}

export default FirebaseRepository;
